<template>
  <div class="content container">
    <h1>Update Payment Method</h1>

    <div class="card p-4">
      <b-alert
        v-if="formErrors.non_field_errors !== undefined"
        variant="danger"
        show
      >
        {{ formErrors.non_field_errors[0] }}
      </b-alert>

      <p class="font-weight-bolder">
        Update Card
      </p>
      <div
        class="stripe-payment-form"
      >
        <div id="card-element" />
        <div
          id="card-errors"
          class="alert-danger"
          role="alert"
        />
      </div>
      <button
        class="btn btn-primary mt"
        :disabled="formSubmitting"
        @click="submitFormStep1"
      >
        Update
      </button>
    </div>
  </div>
</template>

<script>
import Stripe from '@/mixins/Stripe.vue';

export default {
  name: 'UpdatePaymentMethod',
  mixins: [Stripe],
  props: {
    id: { type: [String, Number], required: true },
  },
  data() {
    return {
      formSubmitting: false,
      formErrors: {},
    };
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  methods: {
    submitFormStep1() {
      this.formSubmitting = true;
      this.formErrors = {};

      this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card,
      }).then((result) => {
        if (result.error) {
          const cardErrors = document.getElementById('card-errors');
          cardErrors.classList.add('alert');
          cardErrors.textContent = result.error.message;
          this.formSubmitting = false;
        } else {
          this.submitFormStep2(result.paymentMethod.id);
        }
      });
    },
    async submitFormStep2(token) {
      const postData = { token };

      let response;

      try {
        response = await fetch(
          `${process.env.VUE_APP_API_URL}enrollments/${this.id}/update_payment_method/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${this.userData.drf_token}`,
            },
            body: JSON.stringify(postData),
          },
        );
      } catch (e) {} // eslint-disable-line no-empty

      this.formSubmitting = false;

      if (response && [201, 400, 401].includes(response.status)) {
        const responseJson = await response.json();

        if (response.status === 201) {
          this.$router.push({ name: 'manage_programs' });
        } else if (response.status === 400) {
          this.formErrors = responseJson;
        } else {
          this.logUserOut();
        }
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .content {
    max-width: 400px;
    height: 100vh;
  }
</style>
